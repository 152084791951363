import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Row, Col, Typography, Button } from 'antd'

import Layout from '../components/layout'
import Head from '../components/head'
import BetaTestersLogos from '../components/beta-testers-logos'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import CtaRow from '../components/cta-row'
import FreeTrialCta from '../components/free-trial-cta'

const { Title, Paragraph, Text } = Typography

const TurboAlternatives = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "rocketchart-dashboard-v2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      comparaisonTarifsImage: file(relativePath: { eq: "comparaison-tarifs-turbopilot-rocketchart.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      interfaceImage: file(relativePath: { eq: "rocketchart-overview-dashboard-v2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      rapideImage: file(relativePath: { eq: "rocketchart-budget-previsionnel.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      feedbackImage: file(relativePath: { eq: "agicap-rocketchart-feedback.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      michaelMichettiImage: file(relativePath: { eq: "michael-michetti.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      benjaminImage: file(relativePath: { eq: "benjamin_cahen_wisepops_rocketchart.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      wisepopsImage: file(relativePath: { eq: "wisepops-logo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 40)
        }
      }
      emSolarImage: file(relativePath: { eq: "em-solar-log.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
      arrowThreeImage: file(relativePath: { eq: "arrow-down-3.png" }) {
        childImageSharp {
          gatsbyImageData(height: 90)
        }
      }
      turboLogoImage: file(relativePath: { eq: "logo-turbopilot.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      rocketchartLogoImage: file(relativePath: { eq: "logo-rocketchart.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const description = 'Découvrez RocketChart, la nouvelle alternative à TurboPilot. Plus rapide, plus moderne, moins comptable. Gérez et anticipez votre trésorerie facilement.'

  return (
    <Layout>
      <Head
        title="La Meilleure Alternative à TurboPilot"
        description={description}
        canonical="https://rocketchart.co/turbopilot-alternative/"
      >
      </Head>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle" style={{ marginRight: 0, marginLeft: 0 }}>
        <Col xs={24} lg={11}>
          <Title>Découvrez la meilleure alternative à TurboPilot</Title>
          <Paragraph style={{ color: 'black' }}>Vous recherchez une alternative au logiciel de trésorerie TurboPilot ? Quel est le meilleur outil de gestion de trésorerie ? Envisagez RocketChart, la dernière solution française pour gérer et prévoir simplement votre trésorerie.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Connectez vos banques pour automatiser votre suivi en temps réel et gagner du temps. Prenez des décisions éclairées à partir de vos différents scénarios prévisionnels.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Voici notre comparaison entre RocketChart et TurboPilot.</Paragraph>

          <CtaRow />
        </Col>
        <Col xs={24} lg={13} justify="center">
          <GatsbyImage image={data.mainImage.childImageSharp.gatsbyImageData} alt="RocketChart, logiciel de gestion et prévision de trésorerie" className="rounded-image rc-basic-shadow" />
        </Col>
      </Row>

      <Row className="content-container" gutter={40} type="flex" justify="center" style={{ marginTop: '30px' }}>
        <Col span={24} align='center'>
          <Text strong style={{ color: '#6a737d' }}>Ils ont préféré RocketChart pour gérer leur trésorerie</Text>
        </Col>
        <BetaTestersLogos />
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" justify="center">
        <Col xs={{ span: 24 }} lg={{ span: 18 }} align='center'>
          <Title level={2}>Vous cherchez une alternative à TurboPilot ?</Title>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 14 }} style={{ marginTop: '50px' }}>
          <Paragraph style={{ color: 'black' }}>TurboPilot est une solution éditée par l’entreprise RCA Consulting, créée en 1997. C’est une société de consultants et d’éditeurs de logiciels à destination des Experts-Comptables. Leur objectif est de faciliter la gestion et l’organisation des cabinets comptables.</Paragraph>
          <Paragraph style={{ color: 'black' }}>RocketChart et TurboPilot sont deux solutions très différentes. Les principales fonctionnalités pour gérer efficacement la trésorerie sont présentes dans chaque logiciel. Mais la solution TurboPilot a été conçue pour les Experts-Comptables : le logiciel conserve donc une approche comptable dans son utilisation. À l’inverse, RocketChart est pensé pour les dirigeants et DAF. La vision est analytique, sans aucune notion comptable.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Si vous cherchez à remplacer TurboPilot ou comparer les outils, voici un comparatif qui vous fera sérieusement considérer RocketChart.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={10} justify="center">
          <Paragraph align='center'>
            <GatsbyImage image={data.comparaisonTarifsImage.childImageSharp.gatsbyImageData} style={{ maxWidth: 300 }} alt="Tarifs TurboPilot RocketChart" />
          </Paragraph>
        </Col>
        <Col xs={24} lg={14}>
          <Title level={2}>Notre solution simple et intuitive ne nécessite pas d’accompagnement obligatoire et payant.</Title>
          <Paragraph style={{ color: 'black' }}>La solution TurboPilot est très complète et couvre une bonne partie des besoins quant à la gestion de votre entreprise (calcul des marges, fichiers des écritures comptables, catégorisation comptable, etc.). Cet avantage est aussi son point faible : la solution est trop complexe à prendre en main.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Vous devez donc passer par une phase de démonstration du logiciel, avant d’être accompagné obligatoirement pendant 4h. La société éditant TurboPilot facture 297€ HT /heure cette prestation. Le coût de setup revient donc à 1188€ HT.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Chez RocketChart, nous faisons tout pour rendre notre interface claire et intuitive. Car suivre sa trésorerie doit être simple. Vous prenez en main notre logiciel en toute autonomie, et nous restons disponibles sur le Live Chat si besoin d’assistance. Vous pouvez aussi réserver une démo gratuite avec un des fondateurs.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={12}>
          <Title level={2}>Une entreprise humaine qui écoute vos feedbacks.</Title>
          <Paragraph style={{ color: 'black' }}>Nous n’avons pas des milliers d’utilisateurs. Des centaines nous conviennent largement. Chacun d’eux est unique. Chacun d’eux est écouté. Nous connaissons le nom et prénom de tous nos clients. Car nous développons cette solution pour eux, pour vous.</Paragraph>
          <Paragraph style={{ color: 'black' }}>C’est grâce aux retours de nos utilisateurs que nous améliorons RocketChart tous les jours et qu’il deviendra le meilleur logiciel de trésorerie. Vous n’êtes pas juste un numéro quand vous appelez. Vous n’êtes pas juste un identifiant sur notre Live Chat. Vous êtes Laurence, Maxime, Lionel, Aurélien, Benoît, Florent, Michael, Pierre.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Et c’est grâce à vous, et uniquement vous, que nous pouvons vivre de notre activité aujourd’hui. Et la moindre des choses est de vous écouter.</Paragraph>
        </Col>
        <Col xs={24} lg={12} justify="center">
          <GatsbyImage image={data.feedbackImage.childImageSharp.gatsbyImageData} alt="Importance feedbacks RocketChart" />
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }} justify="center">
          <GatsbyImage image={data.interfaceImage.childImageSharp.gatsbyImageData} alt="Interface Turbopilot RocketChart" />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={2}>Notre logiciel de trésorerie n’a aucune notion comptable.</Title>
          <Paragraph style={{ color: 'black' }}>Vous n’avez ni la formation ni l’envie d’avoir une vue comptable de votre trésorerie. Vous souhaitez créer vos propres catégories analytiques pour suivre votre activité à votre manière. Comme vous pouviez le faire sur Excel - ou Google Sheet. Vous ne choisissez certainement pas un logiciel de trésorerie pour faire votre comptabilité.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Chez RocketChart, notre logiciel ne raisonne pas en terme comptable. Puisque nos clients sont des dirigeants et DAF, nous avons pensé vos dashboards et l’ensemble des interfaces en s’affranchissant de toute notion comptable.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Vous économisez du temps avec un suivi automatisé, vous anticipez votre trésorerie simplement, tout en utilisant un outil compréhensible et analytique.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={12}>
          <Title level={2}>Une solution rapide pour vous faire gagner plus de temps.</Title>
          <Paragraph style={{ color: 'black' }}>Votre temps est important. En tant qu’entrepreneur, dirigeant ou DAF vous maniez constamment plusieurs sujets à la fois. Vous devez être efficace pour réduire votre ToDo et assurer le développement de l’entreprise. Nous connaissons vos frustrations puisque nous vivons la même chose avec le développement de notre entreprise RocketChart.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Depuis le début, notre mission est de vous faire gagner beaucoup du temps. Par exemple avec un suivi de trésorerie automatisée en temps réel. Mais nous souhaitons aller plus loin. Donc nous améliorons en permanence les performances techniques de notre logiciel. Pour qu’il réponde toujours plus vite à vos clics, qu’il affiche vos graphiques en un battement de cils et que vous trouviez l’information d’un seul coup d’oeil.</Paragraph>
        </Col>
        <Col xs={24} lg={12} justify="center">
          <GatsbyImage image={data.rapideImage.childImageSharp.gatsbyImageData} alt="Rapide RocketChart" />
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" justify="center" style={{ marginBottom: '60px' }}>
        <Col xs={{ span: 24 }} align='center'>
          <Title level={2}>Comparez TurboPilot et RocketChart</Title>
        </Col>
      </Row>

      <Row className="content-container" gutter={[40, 20]} style={{ fontSize: 14 }}>
        <Col xs={{ offset: 12, span: 6 }} align='center'>
          <GatsbyImage image={data.turboLogoImage.childImageSharp.gatsbyImageData} alt="logo TurboPilot" style={{ maxHeight: 30 }} imgStyle={{ objectFit: 'contain' }} />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <GatsbyImage image={data.rocketchartLogoImage.childImageSharp.gatsbyImageData} alt="logo RocketChart" style={{ maxHeight: 30 }} imgStyle={{ objectFit: 'contain' }} />
        </Col>

        <Col xs={{ span: 12 }}>
          Profils clients
        </Col>
        <Col xs={{ span: 6 }} align='center' className='rc-bold-text'>
          Experts-Comptables<br />Consultants
        </Col>
        <Col xs={{ span: 6 }} align='center' className='rc-bold-text rc-green-text'>
          Dirigeants<br />DAF
        </Col>

        <Col xs={{ span: 12 }}>
          Accompagnement au paramétrage
        </Col>
        <Col xs={{ span: 6 }} align='center' className='rc-bold-text'>
          Obligatoire<br />297€ /heure
        </Col>
        <Col xs={{ span: 6 }} align='center' className='rc-bold-text rc-green-text'>
          Aucun
        </Col>

        <br />
        <br />
        <br />
        <br />

        <Col xs={{ span: 12 }}>
          Synchronisation multi-comptes bancaires
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Catégorisation automatique des opérations
          </Col>
        <Col xs={{ span: 6 }} align='center' className='rc-bold-text'>
          Règles à définir<br />manuellement
        </Col>
        <Col xs={{ span: 6 }} align='center' className='rc-bold-text rc-green-text'>
          Apprentissage<br />automatique
        </Col>

        <Col xs={{ span: 12 }}>
          Budgets prévisionnels
          </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Comparaison Réalisé vs Budgets prévisionnels
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Trésorerie prévisionnelle
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Multi-scénarios prévisionnels
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Calculs de TVA automatisé
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Export et reporting
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Analyse précise des postes de dépense
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Budgets et tableau d’exploitation
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          Bientôt disponible
        </Col>

        <Col xs={{ span: 12 }}>
          Vision holding / multi-entités
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          Bientôt disponible
        </Col>

        <Col xs={{ span: 12 }}>
          Multi-devises
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          Bientôt disponible
        </Col>

        <Col xs={{ span: 12 }}>
          Calcul des marges
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CloseOutlined className='rc-large-text rc-red-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Comparaison années N-1
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CloseOutlined className='rc-large-text rc-red-text' />
        </Col>

        <br />
        <br />
        <br />
        <br />

        <Col xs={{ span: 12 }}>
          Support (live chat et email)
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Période d’essai gratuit
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Prix d'appel
        </Col>
        <Col xs={{ span: 6 }} align='center' className='rc-bold-text'>
          75€ /mois
        </Col>
        <Col xs={{ span: 6 }} align='center' className='rc-bold-text'>
          79€ /mois
        </Col>
      </Row>

      <br />
      <br />

      <iframe loading="lazy" width="560" height="315" className='max-w-full' title="Merci d'avoir lu le comparatif." src="https://www.youtube.com/embed/evLhCnUfjYE?rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

      <Row className="content-container space-around-row" gutter={40} type="flex" justify="center" style={{ marginBottom: '60px' }}>
        <Col xs={{ span: 24 }} align='center'>
          <Title level={2}>Ils en parlent mieux que nous</Title>
        </Col>

        <Col xs={24} lg={12}>
          <div className="rc-quote-container" style={{ marginTop: 20, marginBottom: 20 }}>
            <div style={{ marginBottom: '10px', marginRight: '10px' }}>
              <GatsbyImage image={data.wisepopsImage.childImageSharp.gatsbyImageData} alt="logo Wisepops" />
            </div>
            <Title level={4}>« Comme nous n'avions pas le bon outil, c'était compliqué de maîtriser notre situation financière à tout moment. Je passais des heures sur Excel pour suivre ma trésorerie ou je devais attendre des semaines le rapport de mon comptable. Sur RocketChart tout est plus simple et instantané. »</Title>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <GatsbyImage image={data.benjaminImage.childImageSharp.gatsbyImageData} alt="Benjamin Cahen - Fondateur - Wisepops" />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '10px' }}>
                <Text strong>Benjamin Cahen</Text>
                <Text>Fondateur - Wisepops</Text>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={24} lg={12}>
          <div className="rc-quote-container" style={{ marginTop: 20, marginBottom: 20 }}>
            <div style={{ marginBottom: '10px', marginRight: '10px' }}>
              <GatsbyImage image={data.emSolarImage.childImageSharp.gatsbyImageData} alt="logo la Note Parisienne" />
            </div>
            <Title level={4}>« Ne cherchez plus, ils l'ont fait - simplement BRAVO !! Fini Excel et fini le temps infini à passer pour gérer sa trésorerie et ses prévisions. RocketChart est très agréable, facile d'utilisation et comporte toutes les fonctionnalités attendues pour une bonne gestion de trésorerie. »</Title>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <GatsbyImage image={data.michaelMichettiImage.childImageSharp.gatsbyImageData} alt="Michael Michetti - Président - EM Solar" />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '10px' }}>
                <Text strong>Michael Michetti</Text>
                <Text>Président - EM Solar</Text>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={{ span: 24 }} align='center' style={{ marginTop: '50px' }}>
          <Link to="#clients">
            <Button size='large' className="rc-cta">Voir plus d'avis</Button>
          </Link>
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: '0px', marginBottom: '60px' }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowThreeImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 3" />
          </div>
        </Col>
        <Col xs={24} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>Démarrez maintenant votre essai gratuit</Title>

          <FreeTrialCta />
        </Col>
      </Row>

    </Layout>
  )
}

export default TurboAlternatives
